<template>
    <div id="page-user-list">
        <div class="vx-card p-6 ml-2">
            <span v-if="!this.packageHistory">{{$t('menuPackage.experience')}}</span>
            <span v-if="this.packageHistory && this.packageHistory.isEndDate">{{$t('menuPackage.package')}} <b class="color">{{this.packageHistory.organizationPackageResponseDTO.packageName}}</b> {{$t('menuPackage.packageHistoryDate')}} {{this.packageHistory.endDate}}</span>
            <span v-if="this.packageHistory && !this.packageHistory.isEndDate">{{$t('menuPackage.package')}} <b class="color">{{this.packageHistory.organizationPackageResponseDTO.packageName}}</b> {{$t('menuPackage.packageHistory')}}. {{$t('menuPackage.renew')}} </span>
            <div class="flex">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto"></span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto" style="font-weight: bold;">{{item.packageName}}</span>
                </div>
            </div>

            <div class="flex" style="background-color: #f5f5f6">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float" style="font-weight: bold;">{{$t('menuPackage.limit')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto"></span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 h-12 flex">

                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.storageTime')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.timeStorage}} {{$t('menuPackage.day')}}</span>

                </div>
            </div>

            <div class="flex">
                <div class="flex-1 bg-grid-color-secondary h-12 flex">
                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.storageCapacity')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 bg-grid-color-secondary h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.s3_quota}} GB</span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.totalCamera')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.totalCamera}} camera</span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 bg-grid-color-secondary h-12 flex">
                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.totalCameraAI')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 bg-grid-color-secondary h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.totalCameraAI}} camera</span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.quality')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.profile == 1 ? "360p" : item.profile == 2 ? " 480p"
                        : item.profile == 3 ? " 640p" : item.profile == 8 ? " 720p" : item.profile == 9 ? " 1080p" : ""}}</span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 bg-grid-color-secondary h-12 flex">

                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.date')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 bg-grid-color-secondary h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.packageDuration}} {{$t('menuPackage.day')}}</span>

                </div>
            </div>

            <div class="flex">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float">{{$t('menuPackage.cost')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto">{{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}} đồng</span>
                </div>
            </div>

            <div class="flex mt-3" style="background-color: #f5f5f6">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float" style="font-weight: bold;">{{$t('menuPackage.functional')}}</span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto"></span>
                </div>
            </div>

            <div v-for="(p, i) in permissionList">
                <div class="flex">
                    <div class="flex-1 h-12 flex mt-5">
                        <span class="sm:flex hidden m-auto header-float">{{p.functionAlias}}</span>
                    </div>
                    <div v-for="item in organizationPackageList" class="flex-1 h-12 flex ">
              <span class="sm:flex hidden m-auto">
                  <div class="flex-1 h-12 flex mt-5">
                        <feather-icon v-if="item[p.functionAlias.toString()]" icon="CheckIcon"
                                      style="width: 1.2rem;color: green;"></feather-icon>
                    </div>
              </span>
                    </div>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1 h-12 flex">
                    <span class="sm:flex hidden m-auto header-float"></span>
                </div>
                <div v-for="item in organizationPackageList" class="flex-1 h-12 flex">
                    <div class="sm:flex hidden m-auto">
                        <vs-button
                            @click="registerPackage(item)"
                            color="primary"
                            type="border">

                            {{$t('button.request')}}

                        </vs-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import moment from 'moment'

export default {
    components: {},
    data() {
        return {
            components: {},
            organizationPackageList: [],
            permissionList: [],
            packageHistory: null
        }
    },
    async created() {
        await this.getPermissionList();
        await this.getPackageActive();
        this.initScreen();
    },
    watch: {},
    computed: {},
    methods: {
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/organization-package/find-all').then((response) => {
                this.organizationPackageList = response.data;
                this.permissionList.forEach(permission => {
                    let functionAlias = permission.functionAlias;
                    this.organizationPackageList.forEach(p => {
                        p[functionAlias.toString()] = false;
                        let permissions = p.permissionFunctions;
                        if (permissions && permissions.length > 0) {
                            let per = permissions.find(per => per.id == permission.id);
                            if (per) {
                                p[functionAlias.toString()] = true;
                            } else {
                                p[functionAlias.toString()] = false;
                            }
                        } else {
                            p[functionAlias.toString()] = false;
                        }
                    })
                });
                this.organizationPackageList = this.organizationPackageList.sort(function (a, b) {
                    return parseInt(a.packageDuration) - parseInt(b.packageDuration);
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getPermissionList() {
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                this.$crm.post('/permission-function/find-all').then((response) => {
                    this.permissionList = response.data;
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    reject(false);
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            });
        },
        registerPackage(item) {
            this.$vs.loading();
            this.$crm.post(`/organization-package-history/create/${item.id}`).then(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                    text: this.$t('menuPackage.singUpPackage') + `${item.packageName}` + this.$t('menuPackage.singUpSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.$router.push('/user/history-register-organization-package');
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getPackageActive() {
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                let url = '/organization-package-history/find-by-org-active';
                this.$crm.get(url).then((response) => {
                    if (response.data.length > 0) {
                        this.packageHistory = response.data[0];
                        this.packageHistory.endDate = moment(new Date(this.packageHistory.createdDate).getTime() +
                            this.packageHistory.organizationPackageResponseDTO.packageDuration * 1000 * 60 * 60 * 24).format("DD/MM/YYYY");
                        this.packageHistory.isEndDate = (new Date(this.packageHistory.createdDate).getTime() +
                            this.packageHistory.organizationPackageResponseDTO.packageDuration * 1000 * 60 * 60 * 24) > new Date().getTime() ? true : false;
                        this.$vs.loading.close();
                    }
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    reject(false);
                });
            })
        },
    },
    mounted() {
    }
}
</script>
<style lang="scss" scoped>
.bg-grid-color-secondary {
    background-color: #dae1e729;
}

[dir] .m-auto {
    color: #2a3035;
}

.header-float {
    margin-left: 10px !important;
}

.vx-card__title > h4 {
    font-weight: bold;
}

@media (max-width: 1366px) {
    #page-user-list {
        margin-left: -13px;
    }
}
</style>
